import XLSX from 'xlsx'

export const renderExcel = (list, header, name = 'export') => {
  console.log('xlsx', list, header)
  const getBuffer = (s) => {
    let buf
    if (typeof ArrayBuffer !== 'undefined') {
      buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)

      for (let index = 0; index < s.length; index++) {
        view[index] = s.charCodeAt(index) & 0xFF
      }
    } else {
      buf = new Array(s.length)
      for (let index = 0; index < s.length; index++) {
        buf[index] = s.charCodeAt(index) & 0xFF
      }
    }
    return buf
  }
  const obj = new Blob([
    getBuffer(XLSX.write({
      SheetNames: ['Sheet1'],
      Sheets: {
        Sheet1: XLSX.utils.json_to_sheet(list, { header })
      },
      Props: {}
    }, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary'
    }))
  ], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })

  const url = (window.URL || window.webkitURL).createObjectURL(obj)
  const anchor = document.createElement('a')

  anchor.download = name
  anchor.href = url
  anchor.click()
}
